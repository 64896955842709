<template>
  <div>
    <b-card
      v-for="(data) in feed"
      :key="data.image"
    >
    <div v-if="data.profileComplete">
      <div
            sm="12"
            class="d-flex justify-content-between justify-content-sm-end align-items-center"

          >
              <feather-icon
                icon="UserIcon"
                size="18"
                class="profile-icon mr-50"
              />
              <span class="mr-1">New User</span>
          </div>
          <div class="d-flex justify-content-start pt-1">
          <b-avatar
            :src="data.image"
            :text="avatarText(data.fullName)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4>
                <b-link
                  :to="{ name: 'apps-users-view', params: { id: data.username }}"
                  class="blog-title-truncate text-body-heading"
                  >{{ data.fullName }}
                </b-link>
              </h4>
              <b-badge variant="primary">
                <feather-icon
                  icon="StarIcon"
                  class="mr-25"
                />
                <span>{{ data.industry }}</span>
              </b-badge>
              <div class="mt-1">
                <span
                  class="badge badge-light-primary badge-skills"
                  v-for="skill in data.skills"
                  :key="skill"
                  :value="skill"> {{ skill }}
                </span>
              </div>
              <div class="mt-1">
                <small class="text-muted">Joined {{ data.date | dateFormat('MMMM DD, YYYY') }}</small>
              </div>
            </div>
          </div>
        </div>

        <!-- Tagline-->
        <div v-if="data.tagline" class="d-flex flex-column ml-1">
          <div class="row">
            <div class="mt-1">
              <i>{{ data.tagline }}</i>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
            sm="12"
            class="d-flex justify-content-between justify-content-sm-end align-items-center"

          >
              <feather-icon
                icon="CoffeeIcon"
                size="18"
                class="profile-icon mr-50"
              />
              <span class="mr-1">New Product or Idea</span>
          </div>
        <div
          class="d-flex justify-content-start align-items-center mb-1"
        >
          <div class="profile-user-info">
            <h2 class="text-primary">
              <b-link
                  :to="{ name: 'pages-ideas-detail', params: data }"
                  class="blog-title-truncate text-body-heading"
                >{{ data.name }}
              </b-link>
            </h2>
            <h6 class="mb-0">
              Posted by <b-link :to="{ name: 'apps-users-view', params: { id: data.username }}" class="text-body">{{ data.username }}</b-link>
            </h6>
            <small class="text-muted">{{ data.date | dateFormat('MMMM DD, YYYY') }}</small>
          </div>
        </div>
        <div class="my-1 py-25">
          <b-badge class="mr-1" variant="primary" v-for="industry in data.industries"
            :key="industry">
            <span>{{ industry }}</span>
          </b-badge>
        </div>
        <b-card-text>
          {{ data.statement }}
        </b-card-text>
        <hr class="my-2">
        <b-card-text class="blog-content-truncate" style="display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;overflow: hidden;">
          <div v-html="data.description"></div>
        </b-card-text>

        <!-- post img -->
        <b-link
          :to="{ name: 'pages-ideas-detail', params: data }"
          class="blog-title-truncate text-body-heading"
        >
          <b-img
            v-if="data.image"
            fluid
            rounded
            class="mb-25"
            :src="data.image"
          />
        </b-link>
        <!--/ post img -->

        <!-- likes comments  share-->
        <b-row class="pb-50 mt-50">
          <b-col
            sm="12"
            class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="18"
              class="profile-icon mr-50"
            />
            <span class="text-muted mr-1">{{ kFormatter(data.comments) }}</span>
          </b-col>
        </b-row>
        <!--/ likes comments  share-->

        <!-- comments -->
        <div
          v-for="(comment,ind) in data.detailedComments"
          :key="ind"
          class="d-flex align-items-start mb-1"
        >
          <b-avatar
            :src="comment.avatar"
            size="34"
            class="mt-25 mr-75"
          />
          <div class="profile-user-info w-100">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">
                {{ comment.username }}
              </h6>
              <b-link class="text-body">
                <feather-icon
                  icon="HeartIcon"
                  size="18"
                  class="mr-25"
                  :class="comment.youLiked ? 'profile-likes' :'profile-icon'"
                />
                <span class="text-muted align-middle">{{ comment.commentsLikes }}</span>
              </b-link>
            </div>
            <small>{{ comment.comment }}</small>
          </div>
        </div>
        <!--/ comments -->
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCardText, BImg, BLink, BRow, BCol, BAvatarGroup, VBTooltip, BFormTextarea, BButton, BFormGroup, BEmbed, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter, avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    BBadge
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    feed: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    kFormatter,
    avatarText
  },
}
</script>
