<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- post -->
        <b-col
          lg="9"
          cols="12"
          order="1"
          order-lg="2"
        >
          <feed-post :feed="feedList" />
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <b-col
          lg="3"
          cols="12"
          order="3"
        >
          <feed-suggested-products :pages-data="suggestedProducts" />
          <feed-suggested-users :suggestions="suggestedUsers" />
        </b-col>
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
        <!-- <b-col
          cols="12"
          order="4"
        >
          <profile-bottom />
        </b-col> -->
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import FeedSuggestedProducts from './FeedSuggestedProducts.vue'
import FeedPost from './FeedPost.vue'
import FeedSuggestedUsers from './FeedSuggestedUsers.vue'
import profileBottom from '../profile/profileBottom.vue'
import moment from 'moment-timezone'
import { getFirestore, getDocs, query, orderBy, where, limit, collection} from 'firebase/firestore'
import { getAuth } from "firebase/auth"

const db = getFirestore()
const auth = getAuth()

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    FeedSuggestedProducts,
    FeedPost,
    FeedSuggestedUsers,
    profileBottom,
  },
  data() {
    return {
      user: null,
      feedList: [],
      unsortedFeedList: [],
      suggestedProducts: [],
      suggestedUsers: [],
      userCreateDate: null,
      profileData: {},
    }
  },
  methods:{
    async getRecentProducts(){
      const snapshot = await getDocs(query(collection(db, "products"), where("status",  "==", "Public"), where("created_time",  ">=", this.userCreateDate), orderBy("created_time", "desc"), limit(25)))
      snapshot.forEach(doc => {
        let product = doc.data()
        product.id = doc.id
        product.date = product.created_time.toDate()

        // Add to suggested products
        if(product.industries && product.industries.includes(this.user.industry))this.suggestedProducts.push(product)
        this.unsortedFeedList.push(product)
      })
      this.getRecentProfiles()
    },
    async getRecentProfiles() {
      const snapshot = await getDocs(query(collection(db, "profiles"), where("profileComplete", "==", true), orderBy("createdAt", "desc"), limit(200)))
      snapshot.forEach(doc => {
        let profile = doc.data()
        profile.id = doc.id
        profile.date = new Date(profile.createdAt.seconds * 1000);

        // Add to suggested profiles
        if(profile.skills && (profile.skills.includes(this.user.pSkills) || profile.skills.includes(this.user.skills)))this.suggestedUsers.push(profile)

        this.unsortedFeedList.push(profile)
      })
      this.sortFeed()
    },
    sortFeed(){
      this.feedList = this.unsortedFeedList.sort(function(a, b) {
        return moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD')
      }).reverse()
    }

  },
  created() {
    this.user = JSON.parse(localStorage.getItem('userData'))
    this.userCreateDate = new Date(auth.currentUser.metadata.creationTime)
    this.getRecentProducts()
    this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
