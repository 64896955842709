<template>
  <b-card>
    <h5 class="mb-2">
      Suggested Products / Ideas
    </h5>

    <div class="profile-user-info" v-if="pagesData.length === 0">
      <small class="text-muted">
        No Suggestions
      </small>
    </div>

    <!-- suggested pages -->
    <div
      v-for="(data,index) in pagesData"
      :key="index"
      class="d-flex justify-content-start align-items-center mt-1"
    >
      <div class="mr-1">
        <b-avatar
          size="40"
          :src="data.image"
        />
      </div>
      <div class="profile-user-info">
        <h6 class="mb-0">
          {{ data.name }}
        </h6>
        <small class="text-muted">{{ data.comments }} Comments</small>
      </div>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        class="btn-icon ml-auto"
        size="sm"
        :to="{ name: 'pages-ideas-detail', params: data }"
      >
        <feather-icon icon="CoffeeIcon" />
      </b-button>
    </div>
    <!--/ suggested pages -->
  </b-card>
</template>

<script>
import { BCard, BAvatar, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAvatar,
    BButton
  },
  directives: {
    Ripple,
  },
  props: {
    pagesData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
